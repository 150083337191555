import $ from 'jquery'
import '../sass/main.scss';
import menu from './menu';
import lazyLoad from './lazyLoad';
//import smoothState from './smoothState';
import animations from './animations';

window.$ = require('jquery');
window.jQuery = require('jquery');

const siteGlobal = {
  initReady() {
    //smoothState.initReady();
  },
  initLoad() {
  	lazyLoad.initLoad();
    menu.initLoad();
    animations.initLoad();
  },
  rebuildAllEvents() {
    lazyLoad.initLoad();
    menu.initLoad();
    animations.initLoad();
  },
  initScroll( ){
  },
  initResize() {
  },
  rebuild() {
    siteGlobal.rebuildAllEvents();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuild();
});

$(window).on('load', () => {
  $('#preloader').addClass('loaded');
  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});
