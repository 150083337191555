import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import configs from './animConfigs';

gsap.registerPlugin(ScrollTrigger);

const animations = {
  initLoad() {
    this.configAnims();
  },
  configAnims() {

    configs.forEach(config => {

      let configClass = `.${config.class}`;
      let configSet = config.set;
      let configStart = config.start;
      let configMarkers = config.markers;
      let configOnEnter = config.onEnter;

      if ($(configClass).length > 0) {

        gsap.set($(configClass), configSet);
        ScrollTrigger.batch(configClass, {
          markers: configMarkers,
          start: configStart,
          onEnter: batch => gsap.to(batch, configOnEnter),
        });

      }

    });

  },

}

module.exports = animations;
