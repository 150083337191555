const configs = [
  {
    class: 'fadeIn',
    set: { opacity: 0 },
    start: 'top 100%',
    markers: false,
    onEnter: { duration:.5, opacity: 1, stagger: { each: 0.15 }, overwrite: true }
  },
  {
    class: 'fadeInUp',
    set: { y: 30, opacity: 0 },
    start: 'top 75%',
    markers: false,
    onEnter: { opacity: 1, y: 0, stagger: { each: 0.15 }, overwrite: true }
  },
  {
    class: 'fadeInLeft',
    set: { xPercent: -100},
    start: 'top 75%',
    markers: false,
    onEnter: { duration:2, xPercent: 0, stagger: { each: 0.25 }, overwrite: true }
  },
  {
    class: 'maskFromLeft',
    set: { clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)'},
    start: 'top 75%',
    markers: false,
    onEnter: { clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0% 100%)', stagger: { each: 0.15 }, overwrite: true }
  },
  {
    class: 'zoomOut',
    set: { scale: 1.2},
    start: 'top 75%',
    markers: false,
    onEnter: { scale: 1, stagger: { each: 0.15 }, overwrite: true }
  },
  {
    class: 'rotate',
    set: { rotate: 0},
    repeat: -1,
    repeatDelay: 1,
    start: 'top 75%',
    markers: false,
    onEnter: { duration:1,rotate: 360, stagger: { each: 0.15 }, overwrite: true }
  }
]

export default configs
